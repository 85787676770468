<style lang="scss" scoped>
$primary: #7459dc;
.vc-weekday,
.vc-title {
  color: $primary !important;
}
.reschedule-header-top {
  margin-top: -3%;
}
</style>
<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<template>
  <section>
    <div class="full-load" v-if="saveDataLoader">
      <h1 v-html="loadingMessage"></h1>
    </div>
    <div class="reschedule-header-top">
      <h3>Reschedule For Appointment</h3>
      <h5>Appointment No:{{ getAppointmentRescheduleDetail.id }}</h5>
      <h6>Name: {{ fullname }}</h6>
    </div>
    <hr />
    <f-calender :back_end="true"></f-calender>
    <div class="col-md-12 mb-5">
      <button
        class="btn btn-success"
        @click="submitReschedule"
        style="float:right"
      >
        Submit
      </button>
      <button class="btn btn-dark mr-2" @click="back" style="float:right">
        Go Back
      </button>
    </div>
  </section>
</template>
<script>
import fCalender from "@/pages/frontend/booknow/calender";
import { mapGetters } from "vuex";
import {
  STORE_RESCHEDULE_TIMES,
  FETCH_NOTES
} from "@/core/services/store/actions.type";
import {
  SET_APPOINTMENT_TIME,
  SET_SCHEDULE_TIMES,
  SET_APPOINTMENT_DATE,
  SET_APPOINTMENT_FOR_RESCHEDULE,
  SET_BREADCRUMB
} from "@/core/services/store/mutations.type";
export default {
  components: {
    fCalender
  },
  data() {
    return {
      saveDataLoader: false,
      loadingMessage: "",
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Appointment List",
          route: "client.appointment.index"
        },
        {
          id: 2,
          title: "Appointment Reschedule",
          route: ""
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "getfrontAppointmentTime",
      "getfrontAppointmentDate",
      "getTimeZone",
      "getAppointmentRescheduleDetail"
    ]),
    fullname() {
      let fullname = this.getAppointmentRescheduleDetail.fname;
      if (this.getAppointmentRescheduleDetail.mname) {
        fullname = fullname + " " + this.getAppointmentRescheduleDetail.mname;
      }
      fullname = fullname + " " + this.getAppointmentRescheduleDetail.lname;
      return fullname;
    }
  },
  created() {
    if (this.getTimeZone == "") {
      this.$router.go(-1);
    }
    let breadCrumbs = this.breadCrumbs;
    breadCrumbs[2]["title"] =
      breadCrumbs[2]["title"] +
      " For Appointment No " +
      this.getAppointmentRescheduleDetail.id;
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(FETCH_NOTES);
    this.$store.commit(SET_APPOINTMENT_TIME, null);
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    submitReschedule() {
      if (this.getfrontAppointmentTime != null) {
        var data = {
          app_id: this.$route.params.app_id,
          token: this.$route.params.token,
          client_id: this.$route.params.id,
          schedule_date: this.$moment(this.getfrontAppointmentDate).format(
            "YYYY-MM-DD"
          ),
          schedule_time: this.getfrontAppointmentTime.start_time,
          time: {
            start: this.timeFormat(
              this.getfrontAppointmentTime.start_time,
              this.getfrontAppointmentDate
            )
          }
        };
        // console.log(data);
        let message = `You want to set your appointment schedule on         
        ${data.schedule_date} and time at ${data.time.start}`;
        this.$swal
          .fire({
            title: "Are you sure?",
            text: message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.isConfirmed) {
              this.loadingMessage =
                "please wait <br> While rescheduling your appointment...";
              this.saveDataLoader = true;
              this.$store
                .dispatch(STORE_RESCHEDULE_TIMES, data)
                .then(res => {
                  if (res.code == 200) {
                    this.$toastr.s(res.msg);
                    this.saveDataLoader = false;
                    this.$store.commit(SET_APPOINTMENT_TIME, null);
                    this.$store.commit(SET_APPOINTMENT_DATE, "");
                    this.$store.commit(SET_SCHEDULE_TIMES, []);
                    this.$store.commit(SET_APPOINTMENT_FOR_RESCHEDULE, null);

                    this.$router.go(-1);
                  }
                })
                .catch(errs => {
                  this.$toastr.e(errs.msg);
                  this.errorMsg = errs.msg;
                  this.saveDataLoader = false;
                });
            }
          });
      } else {
        this.$toastr.w("Please select reschedule time");
      }
    },
    timeFormat(value, date) {
      var fullDate = date + " " + value;
      return this.$moment(fullDate).format("hh:mm a");
    }
  }
};
</script>
