<style lang="scss">
#calender-book {
  h6 {
    font-size: 12px;
  }
  .alert-danger {
    background-color: #f7cece90;
    border: none;
    span {
      color: black;
    }
  }
  .time-schedule-disabled {
    padding: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid rgb(250, 249, 249);
    // cursor: pointer;
    background-color: #cccccc0c;
    color: #ccc;
  }

  .time-schedule {
    padding: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: white;
    border: 1px solid rgb(250, 249, 249);
    cursor: pointer;

    &:hover {
      // box-shadow: none;
      background-color: #7359dc10;
      border: 1px solid #7459dc;
    }

    &.active {
      background-color: #7359dc10;
      border: 1px solid #7459dc;
    }
  }
}
</style>
<template>
  <section id="calender-book">
    <div class="row">
      <div class="col-lg-5">
        <h5>Select Appointment Date</h5>
        <vc-calendar
          :min-date="date"
          is-expanded
          :attributes="attrs"
          v-model="selectedDate"
          @dayclick="onDayClick"
        />
      </div>
      <div class="col-lg-7">
        <div class="row">
          <div class="col-md-12 mb-5">
            <h5 class="mb-2 mt-5">
              Select Time
              <span style="font-size:12px">({{ getTimeZone }})</span>
            </h5>
            <loading-spinner
              v-if="getLoadingData"
              style="margin-top:20px"
            ></loading-spinner>
            <div
              class="row"
              style="padding-bottom: 10px;max-height: calc(100vh -  380px);padding-left: 10px;overflow: auto;"
              v-if="getFrontScheduleTimes.length > 0"
            >
              <div
                class="col-md-3 col-4 pr-1 pl-1"
                style="margin-top: 10px"
                v-for="(timeItem, index) in getFrontScheduleTimes"
                :key="index"
              >
                <div
                  v-if="disabledTime(timeItem)"
                  class="time-schedule text-center"
                  :class="timeItem.active > 0 ? 'active' : ''"
                  @click="selectTime(timeItem)"
                >
                  <span
                    ><strong>{{
                      timeItem.start_time | timeFormat(timeItem.schedule_date)
                    }}</strong></span
                  >
                  <!-- - -->
                  <!-- <span
                    ><strong>{{
                      timeItem.end_time | timeFormat(timeItem.schedule_date)
                    }}</strong></span
                  > -->
                </div>

                <div v-else class="time-schedule-disabled text-center">
                  <span
                    ><strong>{{
                      timeItem.start_time | timeFormat(timeItem.schedule_date)
                    }}</strong></span
                  >
                  <!-- -
                  <span
                    ><strong>{{
                      timeItem.end_time | timeFormat(timeItem.schedule_date)
                    }}</strong></span
                  > -->
                </div>
              </div>
            </div>

            <div
              v-if="getFrontScheduleTimes.length < 1 && !getLoadingData"
              style="margin-top: 35px;text-align: center;"
            >
              <img
                style="width: 13%;"
                src="@/assets/images/icon/png/schedule.png"
                alt="no-schedule"
              />
              <h4
                style="text-align: center; color:#707070;"
                v-if="getSettingNote.no_schedule_note != ''"
                v-html="getSettingNote.no_schedule_note"
              ></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import moment from "moment";
import "moment-timezone";
import { mapGetters } from "vuex";
import {
  FETCH_SCHEDULE_TIMES,
  FETCH_SCHEDULE_TIMES_FOR_BACKEND,
  FETCH_F_NOTE
} from "@/core/services/store/actions.type";
import {
  SET_APPOINTMENT_DATE,
  SET_APPOINTMENT_TIME,
  SET_SETTING_NOTE
} from "@/core/services/store/mutations.type";
import loadingSpinner from "@/components/frontend/spinner.vue";
// import Title from '../../components/frontend/title.vue';
export default {
  components: {
    loadingSpinner
  },
  computed: {
    ...mapGetters([
      "getFrontScheduleTimes",
      "getClientId",
      "getfrontAppointmentDate",
      "getfrontAppointmentTime",
      "getLoadingData",
      "getSettingNote",
      "getTimeZone"
    ])
  },

  data() {
    return {
      selectedDate: {
        date: new Date(),
        string: this.$moment(new Date()).format("Do MMMM YYYY, dddd h:mm:ss a")
      },
      time: this.$moment(new Date()).format("h:mm:ss a"),
      attrs: [
        {
          key: "today",
          highlight: {
            color: "purple",
            fillMode: "outline"
          },
          dates: new Date()
        },
        {
          key: "selected",
          highlight: true,
          dates: ""
        }
      ],
      date: ""
    };
  },

  created() {
    // alert(this.attrs[1].dates);
    this.$store.commit(SET_SETTING_NOTE, {
      no_schedule_note:
        "<h4><strong>No schedule found for the selected date  </strong></h4>"
    });
    this.selectedDate = {
      date: new Date().toLocaleString("en-US", {
        timeZone: this.getTimeZone
      }),
      string: new Date().toLocaleString("en-US", {
        timeZone: this.getTimeZone
      })
    };
    this.date = new Date().toLocaleString("en-US", {
      timeZone: this.getTimeZone
    });
    this.attrs = [
      {
        key: "today",
        highlight: {
          color: "purple",
          fillMode: "outline"
        },
        dates: new Date().toLocaleString("en-US", {
          timeZone: this.getTimeZone
        })
      },
      {
        key: "selected",
        highlight: true,
        dates: new Date().toLocaleString("en-US", {
          timeZone: this.getTimeZone
        })
      }
    ];
    if (this.getfrontAppointmentTime == null) {
      var data = {
        client_id: this.$route.params.id,
        date: this.$moment(new Date())
          .tz(this.getTimeZone)
          .format("YYYY-MM-DD"),
        time_zone: this.getTimeZone
      };
      this.$store.commit(
        SET_APPOINTMENT_DATE,
        this.$moment(new Date())
          .tz(this.getTimeZone)
          .format("YYYY-MM-DD")
      );
      if (this.back_end) {
        this.$store
          .dispatch(FETCH_SCHEDULE_TIMES_FOR_BACKEND, data)
          .then(res => {
            if (res.data.length == 0 && res.msg.remarks) {
              this.$store.commit(SET_SETTING_NOTE, {
                no_schedule_note:
                  "<h4><strong>No schedule found for the selected date due to " +
                  res.msg.remarks +
                  "</strong></h4>"
              });
            }
          });
      } else {
        this.$store.dispatch(FETCH_SCHEDULE_TIMES, data).then(res => {
          if (res.data.length == 0 && res.msg.remarks) {
            this.$store.commit(SET_SETTING_NOTE, {
              no_schedule_note:
                "<h4><strong>No schedule found for the selected date due to " +
                res.msg.remarks +
                "</strong></h4>"
            });
          } else {
            var slug = this.$route.params.client_slug;
            this.$store.dispatch(FETCH_F_NOTE, slug);
          }
        });
      }
    } else {
      this.selectTime(this.getfrontAppointmentTime);
      this.attrs[1].dates = this.$moment(this.getfrontAppointmentDate)._d;
    }

    // this.getTimeZone();
  },
  props: {
    back_end: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    disabledTime(item) {
      return item.show_plan;
    },
    // getTimeZone() {
    //   var offset = new Date().getTimezoneOffset(),
    //     o = Math.abs(offset);
    //   return (
    //     (offset < 0 ? "+" : "-") +
    //     ("00" + Math.floor(o / 60)).slice(-2) +
    //     ":" +
    //     ("00" + (o % 60)).slice(-2) +
    //     " " +
    //     Intl.DateTimeFormat().resolvedOptions().timeZone
    //   );
    // },
    selectTime(item) {
      if (item != null) {
        var key = this.getFrontScheduleTimes.findIndex(
          x => x.start_time === item.start_time
        );
        this.getFrontScheduleTimes.forEach(element => {
          element.active = 0;
        });
        this.getFrontScheduleTimes[key].active = 1;
        this.$store.commit(SET_APPOINTMENT_TIME, item);
      }
    },

    onDayClick(day) {
      var selectedDate = day.id;

      var data = {
        client_id: this.$route.params.id,
        date: selectedDate,
        time_zone: this.getTimeZone
      };
      this.$store.commit(SET_SETTING_NOTE, {
        no_schedule_note:
          "<h4><strong>No schedule found for the selected date  </strong></h4>"
      });
      if (this.back_end) {
        this.$store
          .dispatch(FETCH_SCHEDULE_TIMES_FOR_BACKEND, data)
          .then(res => {
            if (res.data.length == 0 && res.msg.remarks) {
              this.$store.commit(SET_SETTING_NOTE, {
                no_schedule_note:
                  "<h4><strong>No schedule found for the selected date due to " +
                  res.msg.remarks +
                  "</strong></h4>"
              });
            }
          });
      } else {
        this.$store.dispatch(FETCH_SCHEDULE_TIMES, data).then(res => {
          if (res.data.length == 0 && res.msg.remarks) {
            this.$store.commit(SET_SETTING_NOTE, {
              no_schedule_note:
                "<h4><strong>No schedule found for the selected date due to " +
                res.msg.remarks +
                "</strong></h4>"
            });
          } else {
            var slug = this.$route.params.client_slug;
            this.$store.dispatch(FETCH_F_NOTE, slug);
          }
        });
      }

      this.attrs[1].dates = this.$moment(selectedDate)._d;
      this.$store.commit(SET_APPOINTMENT_DATE, selectedDate);
      this.$store.commit(SET_APPOINTMENT_TIME, null);
    }
  },
  filters: {
    timeFormat: function(value, date) {
      var fullDate = date + " " + value;
      var a = moment(fullDate).format("hh:mm a");
      return a;
      // var a = moment.utc(fullDate, 'YYYYMMDD HH:mm:ss');
      // return a.clone().tz(timezone).format('hh:mm a');
    }
  }
};
</script>
